.input-Test{
    width: 95%;
}
.Address-input {
    height: 9vh;
    width: 100%;
}
.TextLable{
    font-size: 18px;
}
.Contant-Test{
    padding: 5%;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    /* height: 80vh; */
}
.choes-Question{
    width: 95%;
    background-color: rgb(241, 241, 241);
    border-radius: 10px;
    /* height: 70vh; */
    /* margin-top: 5vh; */
    padding: 2vh;
}
.TextChoes{
    font-size: 18px;
    color: green;
    text-align: center;
}
.input-Question{
    display: flex;
    padding: 3%;
}
.enter-question{
    width: 88%;
    padding: 15px;
    background-color: gainsboro;
    border-radius: 5px;
}
.add-pictuer{
    width: 5%;
    padding: 15px;
    margin-inline: 1%;
    border-radius: 10px;
    cursor: pointer;
    background-color: gainsboro;
}
.svgimge{
    
}
.Delet-Question{
    width: 5%;
    padding: 15px;
    cursor: pointer;
    padding-right: 20px;
    border-radius: 10px;
    background-color: gainsboro; 
}
.answers{
    padding-right: 3%;
}
.answersContiner{
    width: 90%;
}
.CheckRadio{
    width: 15px;
}
.answerInputText{
    background-color: rgb(241, 241, 241);
    width: 70%;
    height: 5vh;
font-size: 18px;   
 border: none;
}
.AddButton{
    width: 100%;
justify-content: center;
display: flex;

margin-top: 5vh;
}
.Add{
    width: 70%;
    height: 7vh;
    font-size: 18px;
    color: white;
    border-radius: 10px;
    background-color: orange;
}
.inputImage{
    z-index: -1;
    display: none;
}