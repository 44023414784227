.home{
    padding: 85px 40px 60px ;
}
.hr{
    padding: .5px;
}

@media (max-width: 640px) {
    /* Mobile breakpoint */
    .home {
        padding: 80px 80px 80px  30px ; /* Adjusted padding for mobile */
    }

    .main{
        width: 84%;
    }
}

