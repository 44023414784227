.bodyCorsess{
    color: #009539;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: right;
font-family: Almarai;
font-weight: 600;
background-color:white;

}
.special-row{
    background-color: #009539;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    text-align: right;
font-family: Almarai;
font-weight: 400;
color: white;  
}
.iconCorsess{
    color: #009539;
    display: flex;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    justify-content: left;
    text-align: left;
font-family: Almarai;
font-weight: 400;

background-color:white;

position: relative;

}

.special-rowIcon{
   background-color:  #009539;
    display: flex;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    justify-content: left;
    text-align: left;
font-family: Almarai;
font-weight: 400;
color: white;
position: relative;

}
.Address{
    color: #009539;
    border-radius: 15px;
    text-align: right;
font-family: Almarai;
font-weight: 600;
background-color:white;

}

.flage{
  border-radius: 15px;
  background-color:white;
  align-items: center;
    line-height: normal;
    color: #009539;
   font-size: 20px;
   font-weight: 500;
   text-align: center;
   justify-content: center;
   align-items: center;

}

.special-flage{

    border-radius: 15px;
    color: white;
    align-items: center;
    line-height: normal;
    background-color: #009539;
   font-size: 20px;
   font-weight: 500;

   text-align: center;
   justify-content: center;
   align-items: center;  
}
.button {
    background-color: #47BB45 !important;
    color:#fff ;

}
.iconDots{
    position: absolute;
    left:10px;
    cursor: pointer;

}
.paymentOrFree {
    display: flex;
    justify-content: center;
}
.iconDolar{
    background-color: #009539;
    border-radius: 50%;
    margin-right: 7px;
}
.icon-free{

    margin-right: 15px;   
}
.EditAndDelet {
    position: absolute;
    top: 40px;

    left: 10px;
    width: 150px;
    z-index: 10;
    background-color:rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 1px 2px 3px 4px gray;
  }
  
  .dropdown-item {
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  .edit{
    color: black;
    border-bottom: .5px solid gray;
  }
  .delete{
    color:rgb(221, 70, 70)
  }
  .dropdown-item:hover {
    background-color: #cccc;
  }
.space{
    height: 80px;
}