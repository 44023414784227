.editIconProfile{
    position: absolute;
    left: 34px;
    top: 22px;
    cursor: pointer;
}
.input-Test{
    width: 95%;
    margin-bottom: 6vh;
}
.Address-input {
    height: 9vh;
    width: 100%;
    padding-left: 10px !important;
    
}
.select-warning{
 
}
.TextLable{
    font-size: 25px;
    font-weight: 500;
}
.continerPrpfile{
    height: 100vh;
    background-color: white;
    border-radius: 10px;
    padding: 5%;
    margin-top: 3vh;
}
.Buttons{
    display: flex;
    justify-content: center;
}
.save-Edit{
    height: 6vh;
    width: 16%;
    background-color: rgb(9, 100, 15);
    font-size: 20px;
    border-radius: 10px;
    color: white;
    margin-inline: 10%;

}
.chang-pass{
width: 16%;
background-color: orange;
    font-size: 20px;
    color: white;

    border-radius: 10px;
}
