.home{
    padding: 85px 40px 60px ;
}
.home2{
    padding: 285px 40px 60px ;
}

@media (max-width: 640px) {
    /* Mobile breakpoint */
    .home {
        padding: 80px 20px 80px; /* Adjusted padding for mobile */
        width: 100% !important; /* Adjusted padding for mobile */
    }

    .main{
        width: 84%;
    }
}

@media (min-width: 541px) and (max-width: 800px) {
    /* Apply styles when the viewport width is between 541px and 800px */
    .home {
        padding: 80px 25px 80px;
        width: 100%; /* Adjusted padding for this range */
    }

    .main {
        width: 84%;
    }
}





.tabel_head{
color: #006672;
text-align: right;
font-family: Almarai;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.tabel_data {
    color: #514F4F !important;
text-align: center;
font-family: Almarai;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.tabel_data2 {
    color: #FF7A00;
text-align: right;
font-family: Almarai;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 10px;
}

.delete{
    color: #F53F3F;
    font-size: 20px;
}

.brmedium { 
    display: block; 
    margin-bottom: .5em; 
} 

.spaceing{
letter-spacing: 1px !important;
}

.tabel_delete {
    color: #F53F3F !important;
font-family: Almarai;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
font-weight: bold;
}


