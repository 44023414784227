
.Examcontant{

    display: flex;
    justify-content: center;
}

.HeadEXam{
    text-align: center;
    width: 40%;
  
    align-items: center;
}
.TextColor{
    font-weight: 500;
}




.Exam-table{
    width: 100%;
}
.Exam-table-Head{
    width: 100%;

}

.flage-Exam{
    border-radius: 15px;
    background-color:white;
    align-items: center;
      line-height: normal;
      color: #009539;
     font-size: 20px;
     font-weight: 500;

     text-align: center;
     justify-content: center;
     align-items: center;
  
  }
  .Address-Exam{
    color: #009539;
    border-radius: 15px;
    text-align: right;
font-family: Almarai;
font-weight: 600;
background-color:white;

}
  .Address-Exam-red{
    border-radius: 15px;
    text-align: right;
font-family: Almarai;
font-weight: 600;
background-color:white;

}
.Address-Exam-Body{

color: black;    border-radius: 15px;
    text-align: right;
font-family: Almarai;
font-weight: 400;
background-color:white;

}
.checkIcon{
    background-color: #009539;
    border-radius: 50%;
    margin: 7px;
    padding: 1px;
   
}