.home{
    padding: 85px 120px 60px ;
}
.home2{
    padding: 285px 40px 60px ;
}
.hr{
    padding: .5px;
}


@media (max-width: 640px) {
    /* Mobile breakpoint */
    .home {
        padding: 80px 20px 80px; /* Adjusted padding for mobile */
        width: 100% !important; /* Adjusted padding for mobile */
    }

    .main{
        width: 84%;
    }
}

@media (min-width: 541px) and (max-width: 800px) {
    /* Apply styles when the viewport width is between 541px and 800px */
    .home {
        padding: 80px 25px 80px;
        width: 100%; /* Adjusted padding for this range */
    }

    .main {
        width: 84%;
    }
}


