.eyaIcon{
    position: absolute;
    top: 16px;
    left: 10px;
    cursor: pointer;
}
@media (max-width: 640px) {
    /* Mobile breakpoint */
    .eyaIcon {
        top: 11px;
        left: 8px;
    }

  
}