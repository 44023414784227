.home{
    padding: 85px 40px 60px ;
}

.home2{
    padding: 285px 40px 60px ;
}
@media (max-width: 640px) {
    /* Mobile breakpoint */
    .home {
        padding: 80px 80px 80px  30px ; /* Adjusted padding for mobile */
    }

    .main{
        width: 84%;
    }
}

.brmedium { 
    display: block; 
    margin-bottom: .5em; 
} 