@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --main-color:#554D98;
  --text-color:#80C6AD
}

@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');


body {
  overflow-x: hidden;
  margin: 0;
  direction: rtl;
}


