.dropDown {
  visibility: hidden;
}

@media (max-width: 1000px) {
  .dropDown {
    visibility: visible;
    display: flex;
    z-index: 9;
  }
}